/* eslint-disable max-classes-per-file */
const md5 = require('crypto-js/md5');
const Cookie = require('cookie');
const { stores } = require('../../../config');

function keyNeedEncode(keyName) {
  // 隐私关键词
  const keywords = ['ip', 'email', 'token', 'password', 'phone'];

  return keywords.find((item) => keyName.includes(item));
}

function isJSONString(str) {
  try {
    if (
      Object.prototype.toString.call(str) === '[object String]' &&
      Object.prototype.toString.call(JSON.parse(str)) === '[object Object]'
    ) {
      return true;
    }
  } catch (e) {
    // 非JSON对象
  }
  return false;
}

function encryptData(data) {
  if (isJSONString(data)) {
    const jsonObj = JSON.parse(data);
    return JSON.stringify(encryptData(jsonObj));
  }
  if (Object.prototype.toString.call(data) === '[object Object]') {
    const encryptedData = {};
    Object.entries(data).forEach(([key, value]) => {
      if (Object.prototype.toString.call(value) === '[object Object]' || isJSONString(value)) {
        encryptedData[key] = encryptData(value);
      } else if (keyNeedEncode(key)) {
        if (process.env.ENVIRONMENT === 'development') {
          encryptedData[key] = value;
        } else {
          encryptedData[key] = md5(value).toString();
        }
      } else {
        encryptedData[key] = value;
      }
    });
    return encryptedData;
  }
  return data;
}

class Logger {
  constructor({ label } = {}) {
    this.label = label;
  }

  format(content) {
    let formattedMessage = '';
    if (typeof content === 'object') {
      try {
        const encryptedData = encryptData(content);
        formattedMessage = JSON.stringify(encryptedData);
      } catch (e) {
        this.error(`log format error: ${e.message}`, content);
      }
    } else {
      formattedMessage = content;
    }

    return formattedMessage;
  }

  info(...content) {
    this.log('info', ...content);
  }

  error(...args) {
    this.log('error', ...args);
  }

  log(level, ...args) {
    const outputContent = [];
    args.forEach((content) => {
      const formattedStr = this.format(content);
      outputContent.push(formattedStr);
    });

    let outputStr = outputContent.join(' , ');

    if (this.label) {
      outputStr = `[${this.label}] ${outputStr}`;
    }

    if (outputStr.length > 4096) {
      outputStr = outputStr.slice(0, 4096);
    }

    if (console[level]) {
      console[level](outputStr);
    } else {
      console.log(outputStr);
    }
  }
}

/**
 * event 对象结构如下
 * {
 *  "path": "Path parameter (original URL encoding)",
 *  "httpMethod": "Incoming request’s method name",
 *  "headers": {Incoming request headers},
 *  "queryStringParameters": {Query string parameters},
 *  "body": "A JSON string of the request payload",
 *  "isBase64Encoded": "A boolean flag to indicate if the applicable request payload is Base64-encoded"
 * }
 */
/**
 * 进入 function 的日志格式
 * [发起请求的页面] request: 请求方法 path queryStringParameters headers body | hashedEmail: Cookie中的hashed-email
 *
 * function 返回的日志格式
 * [发起请求的页面] response: 响应状态码 响应头 响应数据（状态码 >= 400 时输出）
 */
class FunctionLogger extends Logger {
  constructor(event) {
    super({ label: `refer ${event.headers.referer}` || 'Function' });

    this.event = event;
    const method = event.httpMethod.toLowerCase();

    let app = '';
    if (method === 'get' || method === 'delete') {
      app = event.queryStringParameters.app;
    } else if (isJSONString(event.body)) {
      app = JSON.parse(event.body).app || event.queryStringParameters.app;
    }
    this.app = stores.find((item) => item.app === app)?.app || stores[0]?.app;
  }

  requestLog() {
    const { cookie, ...headers } = this.event.headers;

    const parsedCookie = Cookie.parse(cookie || '');

    const hashedEmail = parsedCookie[`${this.app}-hashed-email`] || '';

    const requestData = encryptData(this.event.body) || '';
    const requestHeaders = encryptData(headers);

    this.log(
      'info',
      `request: ${this.event.httpMethod} ${this.event.path} ${JSON.stringify(
        this.event.queryStringParameters,
      )} ${JSON.stringify(requestHeaders)} ${JSON.stringify(requestData)} | hashedEmail: ${hashedEmail}`,
    );
  }

  responseLog(data) {
    const { statusCode, headers, ...responseData } = data;

    const encryptedData = encryptData(responseData);
    if (statusCode >= 400) {
      this.error(`response error: ${statusCode} ${JSON.stringify(encryptedData)}`);
    } else {
      this.info(`response: ${statusCode}`);
    }
  }
}

/**
 * fetch 发起请求日志格式
 * [请求地址] request: 请求方法 headers body
 *
 * fetch 返回的日志格式
 * [请求地址] response: 响应状态码 响应头 响应数据（状态码 >= 400 时输出）
 */
class FetchLogger extends Logger {
  constructor({ url } = {}) {
    super({ label: `fetch ${url}` });

    this.url = url;
  }

  requestLog(fetchParams, config = {}) {
    const encrypt = config?.encrypt ?? true;

    let requestData;
    let requestHeaders;

    const { cookie, ...fetchParamsHeaders } = fetchParams.headers || {};

    if (encrypt) {
      requestData = encryptData(fetchParams.body || {});
      requestHeaders = encryptData(fetchParamsHeaders);
    } else {
      requestData = fetchParams.body || {};
      requestHeaders = fetchParamsHeaders;
    }

    this.info(`request: ${fetchParams.method} ${JSON.stringify(requestHeaders)} ${JSON.stringify(requestData)}`);
  }

  responseLog(response) {
    if (response.status >= 400) {
      response
        .clone()
        .text()
        .then((responseText) => {
          const encryptedData = encryptData(responseText);
          this.error(`response error: ${response.status} ${encryptedData}`);
        });
    } else {
      this.info(`response: ${response.status}`);
    }
  }
}

function loggerContainer(handler) {
  return async (event) => {
    const functionLogger = new FunctionLogger(event);

    functionLogger.requestLog();

    const responseData = await handler(event, functionLogger);

    functionLogger.responseLog(responseData);

    return responseData;
  };
}

module.exports = {
  Logger,
  FunctionLogger,
  FetchLogger,
  loggerContainer,
  encryptData,
};
