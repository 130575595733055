import { getApp } from '@/utils';
import { normalize } from '@/utils/metafield';
import { stores } from '@@/config';
import Cookies from 'js-cookie';

// start_ai_generated
const getRequestUrl = (app) => {
  const { shopify } = getApp(app);
  if (shopify.shopifyDomain) {
    return `https://${shopify.shopifyDomain}/api/2023-10/graphql.json`;
  }
  return `https://${app}.myshopify.com/api/2023-10/graphql.json`;
};
// end_ai_generated

const getCustomerRequestApi = (app) => {
  return '/api/storefront-customer-api';
};

export const getShippingAddress = ({ app, storeAccessToken, first = 10 }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query getCustomer($customerAccessToken: String!) {
          customer(customerAccessToken: $customerAccessToken) {
            defaultAddress {
              id
              firstName
              lastName
              address1
              address2
              province
              provinceCode
              city
              zip
              country
              countryCodeV2
              phone
            }
            addresses(first: ${first}, reverse: true) {
              nodes {
                id
                firstName
                lastName
                address1
                address2
                province
                provinceCode
                city
                zip
                country
                countryCodeV2
                phone
              }
              pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
          }
        }`,
      variables: {},
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      const { customer } = res.data;
      const { defaultAddress, addresses } = customer;

      // start_ai_generated
      return {
        defaultAddress,
        addresses: addresses.nodes,
        pageInfo: addresses.pageInfo,
      };
      // end_ai_generated
    });
};

// start_ai_generated
export const createShippingAddress = ({ app, storeAccessToken, address }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
          customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
            customerAddress {
              id
              firstName
              lastName
              address1
              address2
              province
              city
              zip
              country
              phone
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        address,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerAddress, customerUserErrors } = res.data.customerAddressCreate;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return customerAddress;
    });
};
// end_ai_generated

// start_ai_generated
export const setShippingAddressDefault = ({ app, storeAccessToken, addressId }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
          customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
            customer {
              defaultAddress { 
                id
              }
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        addressId,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerUserErrors } = res.data.customerDefaultAddressUpdate;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return true;
    });
};
// end_ai_generated

// start_ai_generated
export const deleteShippingAddress = ({ app, storeAccessToken, addressId }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
          customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
            deletedCustomerAddressId
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        id: addressId,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerUserErrors } = res.data.customerAddressDelete;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return true;
    });
};
// end_ai_generated

// start_ai_generated
export const updateShippingAddress = ({ app, storeAccessToken, addressId, address }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
          customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
            customerAddress {
              id
              firstName
              lastName
              address1
              address2
              province
              city
              zip
              country
              phone
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        id: addressId,
        address,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerAddress, customerUserErrors } = res.data.customerAddressUpdate;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return customerAddress;
    });
};
// end_ai_generated

const metafieldsItems = `
  value
  type
  description
  namespace
  key
`;
export const getShopInfo = ({ app, storeAccessToken }) => {
  return fetch(getRequestUrl(app), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query getShopInfo {
          shop {
            name
            moneyFormat
            shipsToCountries
            MF_checkoutValidatorInfo: metafield(namespace: "global", key: "checkout_validator_info") {
              ${metafieldsItems}
            }
            MF_checkoutAddressInfo: metafield(namespace: "global", key: "checkout_address_info") {
              ${metafieldsItems}
            }
            MF_localeSwitcherConfig: metafield(namespace: "global", key: "localeswitcher") { 
              ${metafieldsItems}
            }
            MF_sideMenu: metafield(namespace: "account", key: "side_menu") {
              ${metafieldsItems}
            }
            MF_header: metafield(namespace: "account", key: "header") {
              ${metafieldsItems}
            }
            MF_footer: metafield(namespace: "account", key: "footer") {
              ${metafieldsItems}
            }
            MF_customerService: metafield(namespace: "account", key: "customer_service") {
              ${metafieldsItems}
            }
            MF_creditTask: metafield(namespace: "account", key: "credit_task") { 
              ${metafieldsItems}
            }
            MF_creditInfo: metafield(namespace: "account", key: "credit_info") { 
              ${metafieldsItems}
            }
            MF_codeInfo: metafield(namespace: "account", key: "code_info") { 
              ${metafieldsItems}
            }
            MF_announcementBar: metafield(namespace: "account", key: "announcement_bar") { 
              ${metafieldsItems}
            }
            MF_policies: metafield(namespace: "account", key: "policies") { 
              ${metafieldsItems}
            }
            MF_advertisement: metafield(namespace: "account", key: "advertisement") { 
              ${metafieldsItems}
            }
            MF_plusMember: metafield(namespace: "account", key: "plusMember") { 
              ${metafieldsItems}
            }
            MF_cookieConsentSettings: metafield(namespace: "cookieConsent", key: "settings") { 
              ${metafieldsItems}
            }
            MF_disableBeforeConsent: metafield(namespace: "cookieConsent", key: "disableBeforeConsent") { 
              ${metafieldsItems}
            }
            MF_memberSetting: metafield(namespace: "member", key: "setting") { 
              ${metafieldsItems}
            }
            MF_giftCard: metafield(namespace: "account", key: "giftCard") { 
              ${metafieldsItems}
            }
          }
        }`,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { shop } = res.data;

      const shopData = {};
      const shopMetafields = {};

      Object.entries(shop).forEach(([key, value]) => {
        if (key.startsWith('MF_')) {
          const [, keyName] = key.split('_');
          shopMetafields[keyName] = normalize(value);
        } else {
          shopData[key] = value;
        }
      });

      return {
        shop: shopData,
        shopMetafields,
      };
    });
};

const normalizeProduct = (product) => {
  return {
    ...product,
    variants: product.variants.nodes.map((variant) => ({
      ...variant,
      sellingPlanAllocations: variant.sellingPlanAllocations.edges.map(({ node }) => node),
    })),
  };
};

export const getProduct = ({ handle, app, storeAccessToken }) => {
  return fetch(getRequestUrl(app), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query GetProductByHandle($handle: String!) {
         product(handle: $handle) {
            id
            handle
            availableForSale
            title
            description
            descriptionHtml
            productType
            tags
            productType
            requiresSellingPlan
            variants(first: 10) {
              nodes {
                id
                sku
                title
                quantityAvailable
                currentlyNotInStock
                availableForSale
                barcode
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
                }
                sellingPlanAllocations(first: 10) {
                  edges {
                    node {
                      sellingPlan{
                        id
                        name
                        priceAdjustments {
                          adjustmentValue {
                            ... on SellingPlanFixedAmountPriceAdjustment {
                              __typename
                              adjustmentAmount {
                                amount
                                currencyCode
                              }
                            }
                            ... on SellingPlanFixedPriceAdjustment {
                              __typename
                              price {
                                amount
                                currencyCode
                              }
                            }
                            ... on SellingPlanPercentagePriceAdjustment {
                              __typename
                              adjustmentPercentage
                            }
                          }
                        }
                        options {
                          name
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      variables: { handle },
    }),

    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
      const { product } = res.data;
      return {
        product: normalizeProduct(product),
      };
    });
};

export const getPageMetafields = ({ app, storeAccessToken, handle, metafieldIdentifiers }) => {
  return fetch(getRequestUrl(app), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query getPageInfoQuery($handle: String!, $identifiers: [HasMetafieldsIdentifier!] = []) {
          page(handle: $handle) {
            metafields(identifiers: $identifiers) {
              ${metafieldsItems}
            }
          }
        }`,
      variables: {
        handle,
        identifiers: metafieldIdentifiers,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { page } = res.data;
      if (!page) {
        return undefined;
      }

      const metafields = {};
      page.metafields
        .filter((item) => !!item)
        .forEach(({ namespace, key, ...other }) => {
          if (!metafields[namespace]) {
            metafields[namespace] = {};
          }
          metafields[namespace][key] = normalize(other);
        });

      return metafields;
    });
};
